<script setup lang="ts">
import { format, sub } from 'date-fns';
import AppCalendar from '~/components/app/AppCalendar.vue';
import LineChart from '~/components/chart/LineChart.vue';

defineProps<{
  size: 'sm' | 'lg';
}>();

const agentStore = useAgentsStore();

const spellIdParam = useRouteParams('spellId');
const { routeName } = useRouteName();

const dateRange = ref({
  start: sub(new Date(), { days: 30 }),
  end: new Date(),
});

const spellId = computed(() =>
  routeName.value === 'agent.analytics'
    ? agentStore.agentSpellId
    : spellIdParam.value,
);

const selectedMetric = ref(
  routeName.value === 'agent.analytics' ? 'step_run_count' : 'graph_run_count',
);

const metrics = [
  { key: 'graph_run_count', title: 'Spell Runs' },
  { key: 'step_run_count', title: 'Step Runs' },
];

const {
  data: insightData,
  pending,
  execute,
} = await useApi('/api/runs/insights', {
  method: 'POST',
  body: {
    dateRange,
    spellId,
  },
  immediate: false,
  lazy: true,
});

onMounted(async () => {
  await execute();
});

const chartData = computed(() => {
  if (!insightData.value?.records) return { labels: [], data: [] };
  return {
    labels: insightData.value.records.map((item) => item.date),
    data: insightData.value.records.map((item) => item[selectedMetric.value]),
  };
});

const totalRuns = computed(() => {
  if (!insightData.value) return 0;
  return selectedMetric.value === 'graph_run_count'
    ? insightData.value.totalGraphRuns
    : insightData.value.totalStepRuns;
});

const dateRangeDisplay = computed(() => {
  return `${format(dateRange.value.start, 'MMM d, yyy')} - ${format(dateRange.value.end, 'MMM d, yyy')}`;
});

const percentChange = computed(() => {
  if (!insightData.value) return null;
  return selectedMetric.value === 'graph_run_count'
    ? insightData.value.graphRunPercentChange
    : insightData.value.stepRunPercentChange;
});

const percentChangeDisplay = computed(() => {
  const change = percentChange.value;
  if (change === null) return null;
  const sign = change >= 0 ? '↑' : '↓';
  return `${Math.abs(change).toFixed(2)}% ${sign}`;
});

const chartColors = {
  graph_run_count: { border: '#9296fa', point: '#f2f3fe' },
  step_run_count: { border: '#5bc27a', point: '#dcf6e1' },
};
</script>

<template>
  <div
    class="border-container p-m bg-white w-full gap-3 flex flex-col rounded-2xl items-start"
  >
    <span class="flex w-full justify-between">
      <AppCalendar v-model="dateRange" type="daterange" />
      <USelectMenu
        v-model="selectedMetric"
        :size="size === 'sm' ? 'sm' : 'lg'"
        :options="metrics"
        option-attribute="title"
        value-attribute="key"
      />
    </span>
    <div class="border border-gray-200 bg-white rounded-xl p-4 w-full">
      <div v-if="insightData" class="flex flex-col items-start">
        <span
          v-if="percentChangeDisplay"
          class="caption flex gap-1 leading-4"
          :class="{
            'text-green-500': percentChange > 0,
            'text-red-500': percentChange < 0,
            'text-gray-500': percentChange === null,
          }"
        >
          <p class="text-gray-500">Tasks performed</p>
          {{ percentChangeDisplay }}
        </span>
        <span class="flex items-baseline gap-2">
          <p class="title">
            {{ totalRuns }}
          </p>
          <p class="caption">
            {{ selectedMetric === 'graph_run_count' ? 'runs' : 'tasks' }}
          </p>
        </span>
        <p class="caption text-gray-400 truncate leading-4">
          {{ dateRangeDisplay }}
        </p>
      </div>
      <USkeleton v-else-if="pending" class="h-20 w-40" />

      <client-only>
        <USkeleton
          v-if="pending"
          class="w-full"
          :class="size === 'sm' ? 'h-24' : 'h-40'"
        />
        <LineChart
          v-else
          :labels="chartData.labels"
          :data="chartData.data"
          :label="
            selectedMetric === 'graph_run_count' ? 'Spell Runs' : 'Step Runs'
          "
          :border-color="chartColors[selectedMetric].border"
          :point-background-color="chartColors[selectedMetric].point"
          :title="
            selectedMetric === 'graph_run_count'
              ? 'Spell Runs Over Time'
              : 'Step Runs Over Time'
          "
          :class="size === 'sm' ? 'h-24' : 'h-40'"
        />
      </client-only>
    </div>
  </div>
</template>
